import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import wishlistApi from '../../api/wishlistApi';
import { User } from '../types';
import useCheckAuth from '../hooks/useCheckAuth';

interface AuthContextType {
  user: User | null;
  googleLogin: (user?: User) => void;
  login: (props: { email: string; password: string }) => Promise<unknown>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState<boolean>(false);
  const { data: userData, isLoading, isError } = useCheckAuth();

  useEffect(() => {
    if (userData?.user) {
      setUser(userData.user);
      setIsAuthReady(true);
    }
  }, [userData?.user]);

  const googleLogin = (user?: User) => {
    if (user) {
      setUser(user);
    }
  };

  const login = async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await wishlistApi.post<{ user?: User }>('/auth/login', { email, password });
      const { user } = response ?? {};
      if (user) {
        setUser(user);
      }
      return user;
    } catch (error) {
      console.error('Login Error:', error);
    }
  };

  const logout = async () => {
    try {
      await wishlistApi.post('/auth/logout');
      setUser(null);
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, googleLogin, logout }}>
      {(isLoading || !isAuthReady) && !isError ? <CircularProgress /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
