import React from 'react';
import { Button } from '@mui/material';

const GoogleLoginButton: React.FC = () => {
  return (
    <div>
      <Button
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_API_URL ?? 'http://localhost:5000/v1'}/auth/google`;
        }}
      >
        Google
      </Button>
    </div>
  );
};

export default GoogleLoginButton;
