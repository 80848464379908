import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import useFetchWishlists from '../hooks/useFetchWishlists';
import { APIWishlist } from '../types';
import WishlistsListItem from './WishlistsListItem';
import NoData from '../../common/components/NoData';

export default function WishlistsList() {
  const { data, error, isLoading } = useFetchWishlists();

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {(error as Error).message}</div>;

  return (
    <Box sx={{ marginTop: 2 }}>
      {data?.length ? (
        data.map((wishlist: APIWishlist) => (
          <WishlistsListItem key={wishlist.id} name={wishlist.name} author={wishlist.author.username} />
        ))
      ) : (
        <NoData />
      )}
    </Box>
  );
}
