import React, { useState, useEffect, useMemo } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItemText,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  CssBaseline,
  useMediaQuery,
  Divider,
  ListItemIcon,
  ListItemButton,
  Tooltip,
  Container,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Logout } from '@mui/icons-material';
import { Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from './auth/context/AuthContext';
import './i18n'; // Import i18n configuration
import LoginForm from './auth/components/LoginForm';
import WishlistsList from './wishlists/components/WishlistsList';

const menuConfig = [
  { label: 'home', path: '/', protected: false, role: 'user' },
  { label: 'wishlistsList', path: '/wishlists', protected: true, role: 'user' },
];

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const systemPreferenceDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setDarkMode(systemPreferenceDark);
  }, []);

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode],
  );

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <AppBar position="sticky">
        <Container maxWidth="lg" sx={{ maxWidth: '1440px' }}>
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
                  <List
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                  >
                    <Box>
                      {menuConfig
                        .filter(item => !item.protected || (user && user.roles.includes(item.role)))
                        .map((item, index) => (
                          <ListItemButton key={index} onClick={handleDrawerClose} component={Link} to={item.path}>
                            <ListItemText primary={t(item.label)} />
                          </ListItemButton>
                        ))}
                    </Box>
                    <Box>
                      <Divider />
                      <Box sx={{ padding: 2 }}>
                        {user ? (
                          <>
                            <ListItemButton>
                              <ListItemIcon>
                                {user.profilePicture ? (
                                  <Avatar src={user.profilePicture} sx={{ width: 24, height: 24 }} />
                                ) : (
                                  <AccountCircle />
                                )}
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>{t('profile')}</Typography>
                              </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={logout}>
                              <ListItemIcon>
                                <Logout />
                              </ListItemIcon>
                              <ListItemText>{t('logout')}</ListItemText>
                            </ListItemButton>
                          </>
                        ) : (
                          <Button fullWidth onClick={() => navigate('/login')}>
                            {t('login')}
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </List>
                </Drawer>
              </>
            ) : (
              <Box sx={{ display: 'flex', flexGrow: 1, gap: 2 }}>
                {menuConfig
                  .filter(item => !item.protected || (user && user.roles.includes(item.role)))
                  .map((item, index) => (
                    <Button key={index} color="inherit" component={Link} to={item.path}>
                      {t(item.label)}
                    </Button>
                  ))}
              </Box>
            )}

            {!isMobile ? (
              <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="inherit" onClick={toggleDarkMode}>
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>

                {user ? (
                  <>
                    <Tooltip title={t('profile')}>
                      <IconButton color="inherit">
                        {user.profilePicture ? (
                          <Avatar src={user.profilePicture} sx={{ width: 24, height: 24 }} />
                        ) : (
                          <AccountCircle />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('logout')}>
                      <IconButton color="inherit" onClick={logout}>
                        <Logout />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Button color="inherit" onClick={() => navigate('/login')}>
                    {t('login')}
                  </Button>
                )}
              </Box>
            ) : (
              <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="inherit" onClick={toggleDarkMode}>
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="lg" sx={{ maxWidth: '1440px' }}>
        <Box sx={{ padding: 2 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/wishlists"
              element={user && user.roles.includes('user') ? <WishlistsList /> : <Navigate to="/" />}
            />
            <Route path="/login" element={<LoginForm />} />
          </Routes>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const Home = () => (
  <Typography variant="h4" gutterBottom>
    Home Page
  </Typography>
);

export default App;
