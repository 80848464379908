import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

export default function WishlistsListItem({ name, author }: { name: string; author: string }) {
  return (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {author}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
