import { useQuery } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { User } from '../types';

export default function useCheckAuth() {
  return useQuery({
    queryFn: async () => {
      return await wishlistApi.get<{ user?: User }>('/auth/check');
    },
    queryKey: 'checkAuth',
    retry: 0,
  });
}
