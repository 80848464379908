import React from 'react';
import { Box, Typography } from '@mui/material';

export default function NoData() {
  return (
    <Box>
      <Typography>No data</Typography>
    </Box>
  );
}
