import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const customI18n = i18n;

// Define translations
const resources = {
  en: {
    translation: {
      home: 'Home',
      wishlistsList: 'Wishlists',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
    },
  },
  fr: {
    translation: {
      home: 'Accueil',
      wishlistsList: 'Wishlists',
      login: 'Connexion',
      logout: 'Déconnexion',
      profile: 'Profil',
    },
  },
};

customI18n.use(initReactI18next).init({
  resources,
  lng: 'fr', // Default language
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});

export default customI18n;
